let body = document.querySelector('body');
let burger = document.querySelector('.js-burger');
let buttonUp = document.querySelector('.js-button-up');
let menu = document.querySelector('.js-menu');

burger.addEventListener('click', () => {
    menu.classList.toggle('is-active');
    burger.classList.toggle('is-active');
    body.classList.toggle('is-overflow');
})

let scroll = 0;
window.addEventListener('scroll', function () {
	scroll = window.pageYOffset || document.documentElement.scrollTop;

	if (scroll > 300) {
		buttonUp.classList.add("is-vissible");
	}
	else{
		buttonUp.classList.remove("is-vissible");
	}
});

buttonUp.onclick = function () {
	window.scrollTo(0, 0);
};

const button = document.querySelector('.js-reviews__button');
const hiddenItems = document.querySelectorAll('.js-hidden-item');
let isHidden = true;
button.addEventListener('click', () => {
  button.textContent = isHidden
  ? 'Скрыть'
  : 'Больше';
  
  isHidden = !isHidden;
  hiddenItems.forEach(item => item.classList.toggle('is-hidden'));
});